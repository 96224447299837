// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

@primary-color: #0b2d71;
@secondary-color: #0066b2;
@font-family: "GothamNarrowBook", "Helvetica Neue", Helvetica, Arial, sans-serif;
@font-size-base: 14px;
@icon-color: #0b2d71;
@table-bg: #f3f4f8;
@table-border-color: #f3f4f8;
@table-header-color: white;
@table-header-bg: @secondary-color;
@table-border-color: @secondary-color;
@input-border-color: @primary-color;
@input-hover-border-color: @primary-color;
@select-border-color: @primary-color;
@label-color: #6b6d6f;
@collapse-header-bg: white;
@menu-dark-bg: @primary-color;
@menu-dark-item-hover-bg: #0066b2;
@menu-dark-color: white;
@card-head-background: #064a91;
@card-head-color: white;
@card-background: white;
@btn-primary-color: white;
@btn-default-border: #0081c5;
@btn-default-color: #0081c5;
@btn-primary-bg: #0081c5;
@success-color: #064a91;
@BTN-red-color: #bc0c32;
@Mandate-red-color: #ff4d4f;

body {
  padding-top: 0px;
  padding-bottom: 10px;
  width: 100% !important;
  font-family: "GothamNarrowBook", "Helvetica Neue", Helvetica, Arial,
    sans-serif !important;
  background: #dbdcdd;
}

/* Set padding to keep content from hitting the edges */
.body-content {
  padding-left: 15px;
  padding-right: 15px;
}

.container {
  background: white !important;
}

.div-container {
  padding-top: 40px !important;
  padding-right: 40px !important;
  padding-bottom: 20px !important;
  padding-left: 20px !important;
  border-radius: 0.5em !important;
  border: solid 1px #dbdcdd !important;
  background: whitesmoke !important;
  box-shadow: 4px 2px #dbdcdd !important;
  height: fit-content !important;
  width: 100% !important;
}

.btn-button:hover {
  color: white;
  background: linear-gradient(#0b2d71, #0b2d71);
  background-size: 200% 100%;
  background-position: left right;
  transition: 0.6s;
}

.m-t-b-5 {
  margin-top: 6px !important;
  margin-bottom: 8px !important;
}

// component UI-Design CSS
.steps-action {
  margin-top: 20px;
}

.div-content {
  margin-top: 25px;
}

.div-header {
  margin-top: 15px;
}

.custom-step-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #0b2d71;
}

.custom-step-label-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: red;
}

// .ant-steps-cutom-icon .ant-steps-item-icon {
//   display: none;
// }

.step-content {
  /* 25% of viewport */
  margin-top: 25px;
  height: 100%;
}

.ant-steps-item-disabled .custom-step-label {
  color: gray !important;
}

.ant-steps-item-disabled .anticon {
  color: gray !important;
}

.nzButtonRight {
  text-align: right !important;
}

.loader-container {
  position: fixed;
  height: 100%;
  width: 100%;
  right: 0px;
  top: 0px;
  opacity: 0.9;
  z-index: 30001;
}

.concur-btn {
  background-color: #769231;
  color: white;
  border-color: #769231;
}

.decline-btn,
.withdraw-btn {
  background-color: @BTN-red-color;
  color: white;
  border-color: @BTN-red-color;
}

.mandate {
  color: @Mandate-red-color !important;
}

.d-block {
  display: block !important;
}
.tab-entity-selection-error{
  color: @BTN-red-color !important;
}
.tab-summary-error{
  color: @BTN-red-color !important;
}
.tab-entity-question-error{
  color: @BTN-red-color !important;
}
.tab-entity-proposed-slate-error{
  color: @BTN-red-color !important;
}
.tab-attachment-error{
  color: @BTN-red-color !important;
}
.ant-tabs-tab.ant-tabs-tab-disabled button {
  color:#00000040!important;
}